/* styles.module.css */
.orderNowButton {
    display: inline-block;
    background: linear-gradient(to right, green 33.33%, white 33.33%, white 66.66%, red 66.66%);
    border-radius: 50%;
    color: black;
    text-decoration: none;
    padding: 20px;
    text-align: center;
    transition: background 0.5s, transform 0.5s ease-in-out; /* Add transform to transition for a smoother effect */
    font-family: 'Lora', serif;
    font-weight: bold;
    font-size: 20px;
}

@keyframes waveFlag {
    0%, 100% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(-2deg);
    }
    75% {
        transform: rotate(2deg);
    }
}

.orderNowButton:hover {
    background: linear-gradient(to right, darkgreen 33.33%, white 33.33%, white 66.66%, darkred 66.66%);
    animation: waveFlag 0.5s ease-in-out infinite alternate; /* Apply the waving animation on hover */
    color: black;
}
