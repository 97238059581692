.modalStyle {
  padding: 10px;
  overflow-y: auto;
  max-height: 80vh;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Lora', serif;

}

.modalHeader {
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.modalParagraph {
  margin-bottom: 15px;
  line-height: 1.5;
  font-size: 16px;
}

@media (max-width: 768px) {
  .modalStyle {
      padding: 15px;
      max-height: 70vh;
  }

  .modalHeader {
      font-size: 18px;
      margin-bottom: 15px;
  }

  .modalParagraph {
      font-size: 14px;
  }
}
