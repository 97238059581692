.aboutContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 20px;
    font-family: 'Lora', serif;

}

.aboutHeader {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
    font-weight: bold;
    text-align: center;
}

.aboutContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    margin-top: 20px;
}

.aboutLeft, .aboutRight {
    width: 48%;
}

.aboutText {
    font-size: 1.2em;
    color: #666;
    text-align: justify;
    line-height: 1.6;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .aboutContent {
        flex-direction: column;
        align-items: center;
    }

    .aboutLeft, .aboutRight {
        width: 100%;
        margin-bottom: 20px;
    }

    .aboutRight {
        margin-bottom: 0;
    }
}
