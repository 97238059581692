.footer {
    background-color: #f9f9f9;
    padding: 20px;
    text-align: center;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    font-family: 'Lora', serif;

}

.title {
    font-size: 24px;
    margin-bottom: 15px;
    color: #333;
}

.list {
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.listItem {
    font-size: 16px;
    color: #555;
}

.link {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    font: inherit;
}

.link:hover {
    text-decoration: underline;
    color: inherit;
}

.buttonLink {
    padding: 0;
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    font: inherit;
    text-decoration: none;
}

.buttonLink:hover {
    text-decoration: underline;
    color: #888;
}

.iconLink {
    color: #4267B2;
    font-size: 24px;
    transition: color 0.3s;
}

.iconLink:hover {
    color: #365899;
}

.icon {
    vertical-align: middle;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 150;
}

.modalContent {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.closeButton {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #008d41;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.closeButton:hover {
    background-color: #006633;
}

.noScroll {
    overflow: hidden;
}

.copyright {
    margin-top: 10px;
    font-size: 14px;
    color: #777;
}

/* Responsive Design */
@media (max-width: 768px) {
    .list {
        flex-direction: column;
        gap: 10px;
    }

    .listItem {
        font-size: 14px;
    }

    .iconLink {
        margin-top: 10px;
    }
}
